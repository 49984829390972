import numbro from "numbro"
export const feetToMeters = (ft) => ft / 3.28084
export const metersToFeet = (m) => m * 3.28084
export const feetFloorFromCent = (cm) =>
  cm === "" ? "" : Math.floor(cm / 30.48)
export const inchesRemainFromCent = (cm) =>
  cm === "" ? "" : Math.round(((cm / 30.48) * 12) % 12)
export const feetAndInchesToCent = (ft, inch = 0) =>
  ft === "" && inch === "" ? "" : (ft + inch / 12) * 30.48
export const lbsToKgs = (lbs) => lbs / 2.2046
export const kgsToLbs = (kgs) => kgs * 2.2046

export const readableDistance = ({ meters, gradeType }) => {
  const feet = metersToFeet(meters)
  if (
    (meters < 1000 && gradeType === "french") ||
    (feet < 1000 && gradeType === "us")
  ) {
    return `${numbro(
      Math.round(gradeType === "french" ? meters : feet),
    ).format({ thousandSeparated: true })} ${
      gradeType === "french" ? `m` : "ft"
    }`
  }
  return `${numbro(gradeType === "french" ? meters / 1000 : feet / 5280).format(
    {
      thousandSeparated: true,
      mantissa: 2,
      trimMantissa: true,
    },
  )} ${gradeType === "french" ? `km` : "mi"}`
}

export default {
  feetToMeters,
  metersToFeet,
  feetFloorFromCent,
  inchesRemainFromCent,
  feetAndInchesToCent,
  lbsToKgs,
  kgsToLbs,
}
