import _ from "lodash"

export const problemFeatures = [
  // height
  {
    group: "height",
    title: "Short",
    featureKey: "height",
    value: "short",
    image: "/images/feature-icons/short.png",
  },
  {
    group: "height",
    title: "Average",
    featureKey: "height",
    value: "average",
    image: "/images/feature-icons/average.png",
  },
  {
    group: "height",
    title: "Tall",
    featureKey: "height",
    value: "tall",
    image: "/images/feature-icons/tall.png",
  },
  // hold type
  {
    group: "hold-type",
    title: "Crimps",
    featureKey: "crimpy",
    value: true,
    image: "/images/feature-icons/crimpy.png",
  },
  {
    group: "hold-type",
    title: "Slopers",
    featureKey: "slopey",
    value: true,
    image: "/images/feature-icons/slopey.png",
  },
  {
    group: "hold-type",
    title: "Pinches",
    featureKey: "pinchy",
    value: true,
    image: "/images/feature-icons/pinchy.png",
  },
  {
    group: "hold-type",
    title: "Pockets",
    featureKey: "pockety",
    value: true,
    image: "/images/feature-icons/pockety.png",
  },
  {
    group: "hold-type",
    title: "Jugs",
    featureKey: "juggy",
    value: true,
    image: "/images/feature-icons/juggy.png",
  },
  {
    group: "hold-type",
    title: "Underclings",
    featureKey: "undercling",
    value: true,
    image: "/images/feature-icons/undercling.png",
    isIndoor: true,
  },
  {
    group: "hold-type",
    title: "Crack",
    featureKey: "crack",
    value: true,
    image: "/images/feature-icons/crack.png",
  },
  // footwork
  {
    group: "footwork",
    title: "Small Feet",
    featureKey: "smallFeet",
    value: true,
    image: "/images/feature-icons/small-feet.png",
  },
  {
    group: "footwork",
    title: "Big Feet",
    featureKey: "bigFeet",
    value: true,
    image: "/images/feature-icons/big-feet.png",
  },
  {
    group: "footwork",
    title: "Heel Hooks",
    featureKey: "heelhooks",
    value: true,
    image: "/images/feature-icons/heel-hooks.png",
  },
  {
    group: "footwork",
    title: "Toe Hooks",
    featureKey: "toehooks",
    value: true,
    image: "/images/feature-icons/toe-hooks.png",
  },
  {
    group: "footwork",
    title: "Kneebar",
    featureKey: "kneebar",
    value: true,
    image: "/images/feature-icons/kneebar.png",
  },
  // movement
  {
    group: "movement",
    title: "Static",
    featureKey: "static",
    value: true,
    image: "/images/feature-icons/static.png",
  },
  {
    group: "movement",
    title: "Dynamic",
    featureKey: "dynamic",
    value: true,
    image: "/images/feature-icons/dynamic.png",
  },
  {
    group: "movement",
    title: "Compression",
    featureKey: "compression",
    value: true,
    image: "/images/feature-icons/compression.png",
  },
  {
    group: "movement",
    title: "Tension",
    featureKey: "tension",
    value: true,
    image: "/images/feature-icons/tension.png",
  },
  {
    group: "movement",
    title: "Comp Style",
    featureKey: "coordination",
    value: true,
    image: "/images/feature-icons/coordination.png",
  },
  {
    group: "movement",
    title: "Technical",
    featureKey: "technical",
    value: true,
    image: "/images/feature-icons/technical.png",
  },
  // angle
  {
    group: "angle",
    title: "Slab",
    featureKey: "slab",
    value: true,
    image: "/images/feature-icons/slab.png",
  },
  {
    group: "angle",
    title: "Vertical",
    featureKey: "vertical",
    value: true,
    image: "/images/feature-icons/vertical.png",
  },
  {
    group: "angle",
    title: "Overhang",
    featureKey: "overhang",
    value: true,
    image: "/images/feature-icons/overhang.png",
  },
  {
    group: "angle",
    title: "Steep",
    featureKey: "steep",
    value: true,
    image: "/images/feature-icons/steep.png",
  },
  {
    group: "angle",
    title: "Roof",
    featureKey: "roof",
    value: true,
    image: "/images/feature-icons/roof.png",
  },
  {
    group: "angle",
    title: "Arete",
    featureKey: "arete",
    value: true,
    image: "/images/feature-icons/arete.png",
  },
  {
    group: "angle",
    title: "Dihedral",
    featureKey: "dihedral",
    value: true,
    image: "/images/feature-icons/dihedral.png",
  },
  // start type
  {
    group: "start",
    title: "Sit Start",
    featureKey: "start",
    value: "sitStart",
    image: "/images/feature-icons/sit-start.png",
  },
  {
    group: "start",
    title: "Stand Start",
    featureKey: "start",
    value: "standStart",
    image: "/images/feature-icons/stand-start.png",
  },
  {
    group: "start",
    title: "Jump Start",
    featureKey: "start",
    value: "jumpStart",
    image: "/images/feature-icons/jump-start.png",
  },
  // landing
  {
    group: "landing",
    title: "Flat Landing",
    featureKey: "landing",
    value: "flatLanding",
    image: "/images/feature-icons/flat-landing.png",
  },
  {
    group: "landing",
    title: "Bad Landing",
    featureKey: "landing",
    value: "badLanding",
    image: "/images/feature-icons/bad-landing.png",
  },
  // sun
  {
    group: "sun",
    title: "All Day Sun",
    featureKey: "sun",
    value: "allDaySun",
    image: "/images/feature-icons/all-day-sun.png",
  },
  {
    group: "sun",
    title: "AM Sun",
    featureKey: "sun",
    value: "amSun",
    image: "/images/feature-icons/am-sun.png",
  },
  {
    group: "sun",
    title: "PM Sun",
    featureKey: "sun",
    value: "pmSun",
    image: "/images/feature-icons/pm-sun.png",
  },
  {
    group: "sun",
    title: "Shade",
    featureKey: "sun",
    value: "Shade",
    image: "/images/feature-icons/shade.png",
  },
]

export const routeFeatures = [
  // angle
  {
    group: "angle",
    title: "Slab",
    featureKey: "slab",
    value: true,
    image: "/images/feature-icons/slab.png",
  },
  {
    group: "angle",
    title: "Vertical",
    featureKey: "vertical",
    value: true,
    image: "/images/feature-icons/vertical.png",
  },
  {
    group: "angle",
    title: "Overhang",
    featureKey: "overhang",
    value: true,
    image: "/images/feature-icons/overhang.png",
  },
  {
    group: "angle",
    title: "Steep",
    featureKey: "steep",
    value: true,
    image: "/images/feature-icons/steep.png",
  },
  {
    group: "angle",
    title: "Roof",
    featureKey: "roof",
    value: true,
    image: "/images/feature-icons/roof.png",
  },
  {
    group: "angle",
    title: "Arete",
    featureKey: "arete",
    value: true,
    image: "/images/feature-icons/arete.png",
  },
  {
    group: "angle",
    title: "Dihedral",
    featureKey: "dihedral",
    value: true,
    image: "/images/feature-icons/dihedral.png",
  },
  // hold type
  {
    group: "hold-type",
    title: "Crimps",
    featureKey: "crimpy",
    value: true,
    image: "/images/feature-icons/crimpy.png",
  },
  {
    group: "hold-type",
    title: "Slopers",
    featureKey: "slopey",
    value: true,
    image: "/images/feature-icons/slopey.png",
  },
  {
    group: "hold-type",
    title: "Pinches",
    featureKey: "pinchy",
    value: true,
    image: "/images/feature-icons/pinchy.png",
  },
  {
    group: "hold-type",
    title: "Pockets",
    featureKey: "pockety",
    value: true,
    image: "/images/feature-icons/pockety.png",
  },
  {
    group: "hold-type",
    title: "Jugs",
    featureKey: "juggy",
    value: true,
    image: "/images/feature-icons/juggy.png",
  },
  {
    group: "hold-type",
    title: "Underclings",
    featureKey: "undercling",
    value: true,
    image: "/images/feature-icons/undercling.png",
    isIndoor: true,
  },
  {
    group: "hold-type",
    title: "Crack",
    featureKey: "crack",
    value: true,
    image: "/images/feature-icons/crack.png",
  },
  // footwork
  {
    group: "footwork",
    title: "Small Feet",
    featureKey: "smallFeet",
    value: true,
    image: "/images/feature-icons/small-feet.png",
  },
  {
    group: "footwork",
    title: "Big Feet",
    featureKey: "bigFeet",
    value: true,
    image: "/images/feature-icons/big-feet.png",
  },
  {
    group: "footwork",
    title: "Kneebar",
    featureKey: "kneebar",
    value: true,
    image: "/images/feature-icons/kneebar.png",
  },
  {
    group: "footwork",
    title: "Heel Hooks",
    featureKey: "heelhooks",
    value: true,
    image: "/images/feature-icons/heel-hooks.png",
  },
  // ropework
  {
    group: "ropework",
    title: "Lead",
    featureKey: "lead",
    value: true,
    image: "/images/feature-icons/lead.png",
    isIndoor: true,
  },
  {
    group: "ropework",
    title: "Top-rope",
    featureKey: "toprope",
    value: true,
    image: "/images/feature-icons/toprope.png",
    isIndoor: true,
  },
  {
    group: "ropework",
    title: "Auto-belay",
    featureKey: "autobelay",
    value: true,
    image: "/images/feature-icons/autobelay.png",
    isIndoor: true,
  },
  // energy system
  {
    group: "energy",
    title: "Pumpy",
    featureKey: "pumpy",
    value: true,
    image: "/images/feature-icons/pumpy.png",
  },
  {
    group: "energy",
    title: "Powerful",
    featureKey: "powerful",
    value: true,
    image: "/images/feature-icons/powerful.png",
  },
  {
    group: "energy",
    title: "Technical",
    featureKey: "technical",
    value: true,
    image: "/images/feature-icons/technical.png",
  },
  // safety
  {
    group: "safety",
    title: "Good Pro",
    featureKey: "safety",
    value: "safe",
    image: "/images/feature-icons/safe.png",
  },
  {
    group: "safety",
    title: "Runout",
    featureKey: "safety",
    value: "runout",
    image: "/images/feature-icons/runout.png",
  },
  {
    group: "safety",
    title: "Dangerous",
    featureKey: "safety",
    value: "dangerous",
    image: "/images/feature-icons/dangerous.png",
  },
  // protection
  {
    group: "protection",
    title: "Sport",
    featureKey: "protection",
    value: "sport",
    image: "/images/feature-icons/sport.png",
  },
  {
    group: "protection",
    title: "Mixed",
    featureKey: "protection",
    value: "mixed",
    image: "/images/feature-icons/mixed.png",
  },
  {
    group: "protection",
    title: "Traditional",
    featureKey: "protection",
    value: "traditional",
    image: "/images/feature-icons/trad.png",
  },
  // sun
  {
    group: "sun",
    title: "All Day Sun",
    featureKey: "sun",
    value: "allDaySun",
    image: "/images/feature-icons/all-day-sun.png",
  },
  {
    group: "sun",
    title: "AM Sun",
    featureKey: "sun",
    value: "amSun",
    image: "/images/feature-icons/am-sun.png",
  },
  {
    group: "sun",
    title: "PM Sun",
    featureKey: "sun",
    value: "pmSun",
    image: "/images/feature-icons/pm-sun.png",
  },
  {
    group: "sun",
    title: "Shade",
    featureKey: "sun",
    value: "Shade",
    image: "/images/feature-icons/shade.png",
  },
]

export const problemBools = [
  "crimpy",
  "slopey",
  "pinchy",
  "juggy",
  "pockety",
  "heelhooks",
  "toehooks",
  "bigFeet",
  "smallFeet",
  "compression",
  "tension",
  "coordination",
  "dynamic",
  "static",
  "slab",
  "vertical",
  "overhang",
  "steep",
  "roof",
  "arete",
  "dihedral",
  "kneebar",
  "crack",
  "technical",
  "undercling",
]

export const routeBools = [
  "kneebar",
  "pumpy",
  "powerful",
  "crimpy",
  "slopey",
  "pinchy",
  "juggy",
  "pockety",
  "bigFeet",
  "smallFeet",
  "compression",
  "tension",
  "dynamic",
  "static",
  "slab",
  "vertical",
  "overhang",
  "steep",
  "roof",
  "arete",
  "dihedral",
  "crack",
  "technical",
  "undercling",
  "heelhooks",
]

export const problemEnums = {
  height: ["short", "average", "tall"],
  start: ["sitStart", "standStart", "jumpStart"],
  landing: ["flatLanding", "badLanding"],
  sun: ["allDaySun", "amSun", "pmSun", "Shade"],
}

export const routeEnums = {
  safety: ["safe", "runout", "dangerous"],
  protection: ["sport", "mixed", "traditional"],
  sun: ["allDaySun", "amSun", "pmSun", "Shade"],
}

export const problemColumns = [
  "name",
  "area",
  "grade",
  "description",
  "picture",
  "beta",
  "height",
  "start",
  "landing",
  "sun",
  "crimpy",
  "slopey",
  "pinchy",
  "juggy",
  "pockety",
  "undercling",
  "heelhooks",
  "toehooks",
  "bigFeet",
  "smallFeet",
  "compression",
  "tension",
  "dynamic",
  "static",
  "coordination",
  "technical",
  "slab",
  "vertical",
  "overhang",
  "steep",
  "roof",
  "arete",
  "dihedral",
  "crack",
  "kneebar",
]

export const routeColumns = [
  "name",
  "area",
  "grade",
  "description",
  "picture",
  "height (meters)",
  "beta",
  "gear",
  "numberOfBolts",
  "protection",
  "safety",
  "sun",
  "kneebar",
  "pumpy",
  "powerful",
  "crimpy",
  "slopey",
  "pinchy",
  "juggy",
  "pockety",
  "bigFeet",
  "smallFeet",
  "heelhooks",
  "undercling",
  "technical",
  "compression",
  "tension",
  "dynamic",
  "static",
  "slab",
  "vertical",
  "overhang",
  "steep",
  "roof",
  "arete",
  "dihedral",
  "crack",
]
